<template>
  <CategorySlider
    :title="content.config.title.value"
    :categories="combinedCategoriesWithResolvedMedias"
  />
</template>

<script setup lang="ts">
import type { CmsElement } from "@/types/cms";
import type { Category } from "@/components/organisms/CategorySlider/CategorySlider.interface";
import CategorySlider from "@/components/organisms/CategorySlider";
import { mediaUpdater } from "@/utils/mediaUpdater";
import { useInternalLinkMapper } from "@/composables/useInternalLinkMapper";
import type { Schemas } from "#shopware";

const props = defineProps<{
  content: CmsElement<{
    title: string;
    categories: Category[];
  }>;
}>();

interface ElementData {
  categoryMedias: {
    mediaPreview: Schemas["Media"];
    mediaHover: Schemas["Media"];
  }[];
}

const elementData = props.content.data as ElementData;

const { mapLink } = useInternalLinkMapper();

const combinedCategoriesWithResolvedMedias =
  props.content.config.categories.value.map((category, index) => {
    return {
      ...category,
      mediaPreview: {
        ...category.mediaPreview,
        value: elementData.categoryMedias[index]?.mediaPreview,
      },
      mediaHover: {
        ...category.mediaHover,
        value: elementData.categoryMedias[index]?.mediaHover,
      },
    };
  });

const collectedConfigItemReferences = [];
const collectedResolvedLinkPromises: Promise<string>[] = [];

combinedCategoriesWithResolvedMedias.forEach((category) => {
  if (!category?.link?.value) return;
  collectedConfigItemReferences.push(category.link);
  collectedResolvedLinkPromises.push(mapLink(category.link?.value));
});

const resolvedLinks = (
  await Promise.allSettled(collectedResolvedLinkPromises)
)?.map((linkPromise) => linkPromise?.value);
resolvedLinks.forEach((resolvedLink, index) => {
  collectedConfigItemReferences[index].value = resolvedLink;
});
</script>
